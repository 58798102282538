<template>
  <v-container
    id="create-customer"
    fluid
    tag="section"
  >
    <v-row justify="center">
      <v-col
        cols="12"
        md="10"
      >
        <base-material-card
          color="primary"
        >
          <template v-slot:heading>
            <div class="display-2 font-weight-light">
              Créer un nouveau client
            </div>
          </template>

          <v-form
            method="post"
            ref="client"
            @submit.prevent="validate"
          >
            <v-container class="py-0">
              <v-row>
                <v-col
                  cols="12"
                  md="4"
                >
                  <v-text-field
                    outlined
                    label="Raison sociale *"
                    v-model="client.raisonSociale"
                    :rules="[v => !!v || 'Indiquez la raison sociale']"
                    required
                    @change="formEdited = true"
                  />
                </v-col>
                <v-col
                  cols="12"
                  md="4"
                >
                  <v-text-field
                    outlined
                    label="Nom commercial"
                    v-model="client.nomCommercial"
                    @change="formEdited = true"
                  />
                </v-col>
                <v-col
                  cols="12"
                  md="2"
                >
                  <v-text-field
                    outlined
                    label="Code facture *"
                    v-model="client.codeFacture"
                    :rules="[v => !!v || 'Indiquez le code facture']"
                    required
                    @change="formEdited = true"
                  />
                </v-col>
                <v-col
                  cols="12"
                  md="2"
                >
                   <v-autocomplete
                    outlined
                    :items="['DIRECT', 'ORGANISME']"
                    label="Type *"
                    v-model="client.type"
                    clearable
                    :rules="[v => !!v || 'Indiquez le type']"
                    required
                    @change="formEdited = true"
                  />
                </v-col>

                <v-col
                  cols="12"
                  md="6"
                >
                  <v-text-field
                    outlined
                    label="E-mail"
                    type="email"
                    v-model="client.email"
                    @change="formEdited = true"
                  />
                </v-col>

                <v-col
                  cols="12"
                  md="6"
                >
                  <v-text-field
                    outlined
                    label="Téléphone"
                    type="tel"
                    v-model="client.telephone"
                    @change="formEdited = true"
                  />
                </v-col>

                <v-col
                  cols="12"
                  md="6"
                >
                   <v-autocomplete
                    outlined
                    :items="financeurs"
                    label="Financeur par défaut"
                    item-text="nomCommercial"
                    item-value="id"
                    v-model="client.financeurParDefaut"
                    clearable
                    return-object
                    @change="formEdited = true"
                  />
                </v-col>

                <v-col
                  cols="12"
                  md="3"
                >
                  <v-text-field
                    outlined
                    label="SIREN"
                    v-model="client.siren"
                    type="number"
                    class="no-arrows"
                    @blur="updateSiret()"
                    @change="formEdited = true"
                  />
                </v-col>

                <v-col
                  cols="12"
                  md="3"
                >
                  <v-text-field
                    outlined
                    label="SIRET"
                    type="number"
                    class="no-arrows"
                    v-model="client.siret"
                    @change="formEdited = true"
                  />
                </v-col>

                <v-col
                  cols="12"
                >
                  <v-textarea
                    outlined
                    label="Adresse"
                    v-model="client.adresse.adressePostale"
                    @change="formEdited = true"
                  />
                </v-col>

                <v-col
                  cols="6"
                  md="4"
                >
                  <v-text-field
                    outlined
                    label="Code postal"
                    v-model="client.adresse.codePostal"
                    @change="formEdited = true"
                  />
                </v-col>

                <v-col
                  cols="6"
                  md="4"
                >
                  <v-text-field
                    outlined
                    label="Ville"
                    v-model="client.adresse.ville"
                    @change="formEdited = true"
                  />
                </v-col>

                <v-col
                  cols="6"
                  md="4"
                >
                  <v-text-field
                    outlined
                    label="Pays"
                    v-model="client.adresse.pays"
                    @change="formEdited = true"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  cols="12"
                  class="text-right"
                >
                  <v-btn
                    color="gray"
                    class="mx-1"
                    @click="$router.back()"
                  >
                    Annuler
                  </v-btn>
                  <v-btn
                    v-if="!$route.params.id"
                    color="primary"
                    class="mx-1"
                    type="submit"
                  >
                    Créer le client
                  </v-btn>
                  <v-btn
                    v-if="$route.params.id"
                    color="primary"
                    class="mx-1"
                    type="submit"
                    @click="updateClient()"
                  >
                    Mettre à jour le client
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </base-material-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import { mapMutations } from 'vuex';

  export default {
    data() {
      return {
        formEdited: false,
        client: {
          raisonSociale: '',
          nomCommercial: '',
          codeFacture: '',
          type: '',
          financeurParDefaut: null,
          siren: '',
          siret: '',
          adresse: {},
          nature: 'CLIENT',
          contacts: [],
        },
        financeursAll: [],
        financeurs: [],
      }
    },

    beforeMount() {
      window.addEventListener('beforeunload', this.preventNav);
    },

    beforeDestroy() {
      window.addEventListener('beforeunload', this.preventNav);
    },

    beforeRouteLeave(to, from, next) {
      if (this.formEdited) {
        if (!window.confirm('Les modifications que vous avez apportées seront perdues, souhaitez-vous quitter la page ?')) {
          return;
        }
      }
      next();
    },

    created() {
      if (this.$route.params.id) {
        // edit mode
        this.axios.get('/clients/' + this.$route.params.id)
          .then((res) => {
            this.client = res.data;
            if (!this.client.adresse) {
              this.client.adresse = {};
            }
          })
          .catch(e => {
            this.setSnack({ text: 'Erreur lors de la récupération du client', type: 'warn' });
          });
      }

      this.axios.get('/financeurs')
        .then((res) => {
          this.financeursAll = res.data;
          // edit mode
          if (this.$route.params.id) {
            this.filterFinanceurs();
          }
        })
        .catch((e) => {
          this.setSnack({ text: 'Erreur lors de la récupération des financeurs', type: 'warn' });
        })
    },

    watch: {
      'client.type'(val) {
        this.filterFinanceurs();
      },
    },

    methods: {
      validate () {
        this.formEdited = false;
        if (this.$refs.client.validate()) {
          if (this.$route.params.id) {
            // edit mode
            this.updateClient();
          } else {
            // create mode
            this.createClient();
          }
        }
      },

      filterFinanceurs() {
        if (this.client.type === 'DIRECT' || this.client.type === 'ORGANISME') {
          this.financeurs = this.financeursAll.filter(f => f.type === 'OPCO' || f.type === 'AUTRE');
        } else if (this.client.type === 'PARTICULIER') {
          this.financeurs = this.financeursAll.filter(f => f.type === 'CPF' || f.type === 'PE' || f.type === 'AUTRE');
        } else {
          this.financeurs = [];
        }
      },

      createClient() {
        this.axios.post('/clients', this.client)
          .then(res => {
            this.setSnack({ text: 'Le client a bien été créé', type: 'success' });
            this.$router.push({ name: 'ClientDetail', params: { id: res.data.id } })
          })
          .catch(e => {
            this.setSnack({ text: 'Erreur lors de la création du client', type: 'warn' });
          })
      },

      updateClient() {
        this.lightData();
        this.axios.put('/clients/' + this.client.id, this.client)
          .then(res => {
            this.setSnack({ text: 'Le client a bien été mis à jour', type: 'success' });
            this.$router.push({ name: 'ClientDetail', params: { id: res.data.id } })
          })
          .catch(e => {
            this.setSnack({ text: 'Erreur lors de la mise à jour du client', type: 'warn' });
          })
      },

      updateSiret() {
        if (!this.client.siret || this.client.siret === '') {
          this.client.siret = this.client.siren;
        }
      },

      lightData() {
        delete this.client.contactsAdministratif;
        delete this.client.contactsCommercial;
        delete this.client.contactsFacturation;
        if (this.client.financeurParDefaut) {
          delete this.client.financeurParDefaut.contactsAdministratif;
          delete this.client.financeurParDefaut.contactsCommercial;
          delete this.client.financeurParDefaut.contactsFacturation;
        }
      },

      preventNav(event) {
        if (this.formEdited) {
          event.preventDefault();
          event.returnValue = '';
          this.formEdited = false;
        }
      },

      ...mapMutations({
        setSnack: 'SET_SNACK',
      }),
    },
  }
</script>
